import { WhatsappIcon, PhoneIcon, LocationIcon } from '../icons/icons';

export const MODE_LIST = [
  {
    day: 'пн–пт',
    time: '10 – 21',
  },
  {
    day: 'сб',
    time: '11 – 18',
  },
  {
    day: 'вс',
    time: 'выходной',
  },
];

export const CONTACTS_LIST = [
  {
    icon: WhatsappIcon,
    text: 'Написать',
    href: 'https://wa.me/79859283939',
  },
  {
    icon: PhoneIcon,
    text: '+7 495 928 39 39',
    href: 'tel:+74959283939',
  },
  {
    icon: LocationIcon,
    text: `Лобачевского 
    110 корпус 1`,
    href: 'https://yandex.ru/maps/-/CDxSjK0U',
  },
];
