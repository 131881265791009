import { LogoIcon } from '../../assets/icons/icons';
import './App.css';
import banner from '../../assets/images/banner.webp';
import { CONTACTS_LIST, MODE_LIST } from '../../assets/utils/constants';

function App() {
  return (
    <div className="app">
      <div className="app__content">
        <LogoIcon mainClassName="app__logo" fillClassName="app__logo-fill" />
        <h1 className="app__title">Luman Service</h1>
        <ul className="app__text-list">
          {MODE_LIST.map((item) => (
            <li className="app__text-item" key={item.day}>
              <p className="app__text-item-text">{item.day}</p>
              <p className="app__text-item-text">{item.time}</p>
            </li>
          ))}
        </ul>
        <ul className="app__contacts-list">
          {CONTACTS_LIST.map((item) => (
            <li className="app__contacts-item" key={item.text}>
              <a className="app__contacts-link" href={item.href}>
                <div className="app__contacts-icon-box">
                  {item.icon({
                    mainClassName: 'app__contacts-icon',
                    fillClassName: 'app__contacts-icon-fill',
                  })}
                </div>
                <p className="app__contacts-text">{item.text}</p>
              </a>
            </li>
          ))}
        </ul>
      </div>

      <img className="app__banner" src={banner} alt="banner" />
    </div>
  );
}

export default App;
